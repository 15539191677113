import './AboutUsPage.css';

function AboutUsPage() {
    return (
      <div className="about-us-container">
        <h1 className='h1-nuvotek'>Mai mult decât un studio al creației</h1>
        <h3>O comunitate adaptată stilului de viață al tinerilor</h3>
        <div>
          Nuvotek este un centru de pregătire, care are ca scop dezvoltarea abilităților artistice și care iși propune să revoluționeze procesul creativ, necesar experienței universitare.
        </div>
        <h1 className='h1-nuvotek'>Misiunea noastră</h1>
        <div>
          Este aceea de a oferi o educație corectă în artă, de a dezvolta tânărul cursant într-un adult responsabil și implicat în viitoarele probleme ale societății. Dacă tu crezi că vrei să devii un astfel de artist, cursurile au loc la studioul nostru situat la Piața Iancului. Te așteptăm să te alături uneia dintre grupele noastre zilnice!
        </div>
        <div>
          Fie că vrei să te îndrepți spre arhitectură, design de interior, fashion design sau design de personaje, în România sau peste mări si țări, la noi vei găsi pregătirea adecvată. Dacă încă nu știi către care ramură vrei să te îndrepți pregătirile noastre acoperă bazele fiecărui domeniu, astfel că o să găsim împreună ceea ce ți se potrivește.
        </div>
        <div>
          Spiritul comunității noastre se clădește în baza respectului atât față de artist, cât și față de procesul de formare al acestuia, față de artă, sub orice formă întâlnită, cu multă muncă și perseverență, în vederea idealurilor la care aspirăm. Este mai mult decât un studio al creației, este o comunitate adaptată stilului de viață al tinerilor în care barierele învățământului formal sunt îndepărtate.
        </div>
        <div>
        Vei învăța cum să îți prezinți ideile, cum să le dezbați, cum să te ajuți și să îi ajuți pe alții să le fructifice, cum să asculți și să respecți puncte de vedere diferite, cum să primești și să oferi, la rândul tău, feedback constructiv, cum să dezbați idei, cum să ți le adaptezi la variatele contexte pe care le vei întâlni, atât în cariera pe care ți-o vei forma, cât și în viața ta de zi cu zi.  
        </div>
        <div>
        O fundație viguroasă umană dă cale liberă dezvoltării abilităților specifice domeniului ales. Comunitatea din care vei face parte, alături de noi și de mulți alții ca noi te va sprijini constant în consolidarea arhitecturii tale ca om-artist.
        </div>
        <div className="separator"/>
        <h1 className='h1-nuvotek'>Meet the team</h1>
        <div className='team-info-section'>
          <div className='team-info-section-image-with-caption'>
            <img src='./team_cristina_stanescu.jpg' alt='team-cristina-stanescu'/>
            <div>Cristina Stănescu #NVK01</div>
          </div>
          <div className='team-info-section-text-body'>
            <div>
              Cristina, fondatoarea Nuvotek, a început magia acum 10 ani.
              Încă din timpul facultății de arhitectură, Cristina Stănescu a început să construiască, pas cu pas, drumul către visul de a contribui productiv și constructiv în dezvoltarea societății, prin educație.
            </div>
            <div>
              De 12 ani se dedică cu pasiune și implicare pentru a transmite educația în artă și arhitectură copiilor ce își doresc să se inițieze în aceste domeniu. Îndrumând tineri artiști pentru a deveni arhitecți, designeri și game developers, Cristina a creat o comunitate bazată pe acceptare completă a propriilor personalități, devenind o familie de sute de copii ce păstrează în suflet apartenența la Nuvotek.
            </div>
            <div>
              Echipa este în continuă dezvoltare datorită foștilor elevi ce i-au devenit colegi și este încrezătoare că împreună vor continua să educe și să îndrume tinerii să își urmeze visul.
            </div>
            <div>
              Orientată către viitor, ea a completat structura necesară admiterii la o facultate al cărei domeniu de studiu este arta vizuală, atât cu elemente sociale și culturale, cât și cu cele actualizate, specifice tehnologiei într-o continuă dezvoltare. Structura cursului este creată în așa fel încât să valorifice individul, oferindu-i astfel libertatea de își lasă amprenta asupra propriilor lucrări, descoperind și dezvoltând stilul personal. Cristina își propune ca, alături de echipa ei, să facă parte din îmbunătățirea viitorului prin dezvoltarea de caractere ce vor fi exemple pentru următoarele generații.
            </div>
            <div>
              Copiii au nevoie de exemple, iar tinerii Nuvotek vor fi adulții ce proiectează viitorul.
            </div>
          </div>
        </div>
        <div className='team-info-section'>
          <div className='team-info-section-text-body'>
            Victor Stănescu este unul dintre fondatorii Nuvotek Studio. El oferă atât suport tehnic, cât și suport administrativ. Cu ajutorul lui, lucrurile sunt mai ușoare pentru toți mentorii Nuvotek. În plus, oferă consultanță și suport constant copiilor care aplică la facultăți în străinătate, indiferent de domeniul ales, Nuvotek fiind acreditat UCAS.
          </div>
          <div className='team-info-section-image-with-caption'>
            <img src='./team_victor_stanescu.jpg' alt='team-victor-stanescu'/>
            <div>Victor Stănescu</div>
          </div>
        </div>
        <div className="separator"/>
        <h1 className='h1-nuvotek'>Echipă tutori</h1>
        <div className='team-info-section'>
          <div className='team-info-section-image-with-caption'>
            <img src='./team_adriana_tomeci.jpg' alt='team-adriana-tomeci'/>
            <div>Adriana Tomeci #NVK02</div>
          </div>
          <div className='team-info-section-text-body'>
            <div>
              Adriana Tomeci este tutor la #NVK02 și formator pentru modulele 1 și 2. Absolventă a facultății de arhitectură, cu licență și master la Manchester School of Architecture din UK, Adriana a păstrat mereu arta aproape de suflet. Personalitate multidisciplinară, ea explorează constant diverse forme de comunicare vizuală – de la caiete de schițe la pictură pe pânză, de la markere la uleiuri și, recent, artă digitală, design vestimentar sau regie creativă.
            </div>
            <div>
              Fostă cursantă a Nuvotek Academy, Adriana s-a alăturat echipei ca tutor, pregătind viitoarele generații de arhitecți, designeri și artiști. Cu un stil pedagogic solid, ea îi ajută pe cursanți să-și perfecționeze tehnica de desen, să își dezvolte disciplina de lucru și încrederea în abilitățile lor artistice. Totodată, îi motivează să accepte cu entuziasm provocările din industriile creative.
            </div>
            <div>
              Adriana pune accent pe dezvoltarea gândirii logice și creative, combinând armonios tehnica cu creația. Pentru ea, fiecare design spune o poveste, iar misiunea sa este să îi ajute pe cursanți să-și descopere stilul propriu și să creeze idei vizuale cu impact. Le insuflă pasiunea de a găsi inspirație în mediul înconjurător și de a-și documenta ideile în sketchbook-uri.
            </div>
            <div>
              Cu experiență ca graphic designer în diverse companii, Adriana a început în 2021 să predea cursuri de desen digital în Procreate, în cadrul Nuvotek Academy. În paralel, a fost activă ca voluntar grafician și ilustrator pentru organizații precum Her Time România, Street Delivery și URBOTECA, și a coordonat departamentul de grafică al revistei LZR. Dezvoltarea personală și profesională este o prioritate constantă pentru ea, iar pasiunea și implicarea sa inspiră fiecare generație pe care o îndrumă.
            </div>
          </div>
        </div>
        <div className='team-info-section'>
          <div className='team-info-section-text-body'>
            <div>
              Daria este absolventă a Facultății de Arhitectură de Interior, secția Mobilier și Amenajări de Interior, din cadrul Universității de Arhitectură și Urbanism „Ion Mincu”. Pasionată de design de interior, istoria arhitecturii și artă, Daria își aduce contribuția ca mentor, împărtășind cursanților pasiunea sa pentru estetica vizuală și oferindu-le frecvent perspective din domenii creative conexe, precum cinematografie sau istoria designului.
            </div>
            <div>
              Cu dorința de a forma arhitecți profesioniști și creativi, Daria pune accent pe dezvoltarea simțului estetic și pe încurajarea exprimării libere. Flexibilă și deschisă, creează un mediu educațional diferit de cel tradițional, plăcut și stimulant, în care fiecare curs devine o oportunitate de a inspira tinerii să își exploreze ideile și viziunea.
            </div>
            <div>
              Focalizată pe feedback constructiv și comunicare eficientă, Daria ajută cursanții să își susțină ideile prin reprezentări grafice clare și bine argumentate. În paralel, dezvoltă o cultură estetică generală prin discuții captivante despre artă, arhitectură și design, oferindu-le cursanților un spațiu sigur pentru învățare și explorare creativă. Astfel, Daria modelează viitoarele generații de arhitecți și designeri cu încredere, curaj și viziune.
            </div>
          </div>
          <div className='team-info-section-image-with-caption'>
            <img src='./team_daria_parvu.jpg' alt='team-daria-parvu'/>
            <div>Daria Pârvu</div>
          </div>
        </div>
        <div className='team-info-section'>
          <div className='team-info-section-image-with-caption'>
            <img src='./team_andreea_dima.jpg' alt='team-andreea-dima'/>
            <div>Andreea Dima</div>
          </div>
          <div className='team-info-section-text-body'>
            <div>
              Pasiunea pentru artă și știință au determinat-o să studieze Arhitectura Generală la Universitatea de Arhitectură și Urbanism „Ion Mincu”. Andreea creează un mediu de învățare dinamic, în care cursanții pot explora, învăța și crea, dezvoltându-și atât cunoștințele teoretice, cât și abilitățile practice.
            </div>
            <div>
              Ea împărtășește cursanților perspectiva academică și practică a arhitecturii și designului, pregătindu-i să facă față provocărilor universitare și să își valorifice potențialul. Prin dedicare și motivație, îi încurajează să-și urmeze pasiunea pentru arhitectură, design sau grafică, punând bazele unei cariere de succes.            
            </div>
            <div>
              Pentru Andreea, arhitectura este mai mult decât o profesie – este un stil de viață, o modalitate de a descoperi poveștile spațiilor și locurilor. Fiecare stradă, fiecare colț are o poveste de spus, iar prin schițe și fotografii, ea încearcă să surprindă esența fiecărui loc.
            </div>
            <div>
              Procesul creativ este pentru ea o călătorie, iar scopul este să îndrume fiecare cursant să-și găsească ritmul, să capete încredere în abilitățile proprii și să creeze lucrări care inspiră și comunică cu forță și eleganță.
            </div>
          </div>
        </div>
        <div className='team-info-section'>
          <div className='team-info-section-text-body'>
            <div>
              Larisa este tutore Nuvotek Academy și transformă pasiunea sa pentru această disciplină într-o sursă de inspirație pentru cursanți. Pentru ea, arhitectura este o artă aplicată care îmbină logica cu emoția, funcționalitatea cu estetica și structura cu imaginația. Fiecare proiect devine o oportunitate de a crea armonie între spațiu și suflet.
            </div>
            <div>
              Dedicată dezvoltării cursanților, Larisa îi ajută pe aceștia să își perfecționeze abilitățile de desen tehnic și artistic, să-și exprime ideile creative și să câștige încredere în propriile capacități. Lecțiile sunt adaptate fiecărui cursant, în funcție de nivelul, ritmul și personalitatea acestuia, asigurând o experiență de învățare plăcută și eficientă.
            </div>
            <div>
              Cu răbdare și pasiune, Larisa creează un mediu în care elevii pot explora liber creativitatea, găsind echilibrul între tehnică și inspirație. Prin abordarea sa personalizată, ea le oferă o bază solidă pentru o carieră în arhitectură și îi încurajează să își dezvolte disciplina de lucru, creativitatea și încrederea – abilități esențiale pentru succesul profesional.
            </div>
          </div>
          <div className='team-info-section-image-with-caption'>
            <img src='./team_larisa_constantin.jpg' alt='team-larisa-constantin'/>
            <div>Larisa Constantin</div>
          </div>
        </div>
        <div className='team-info-section'>
          <div className='team-info-section-image-with-caption'>
            <img src='./team_andreea_bunea.jpeg' alt='team-andreea-bunea'/>
            <div>Andreea Bunea</div>
          </div>
          <div className='team-info-section-text-body'>
            <div>
              Andreea, tutor la Nuvotek Academy, inspiră tinerii să fie curioși și pasionați. Pentru ea, arhitectura înseamnă mai mult decât a proiecta clădiri – este despre modul în care spațiile ne influențează starea de spirit și creativitatea cu care modelăm lumea din jur.
            </div>
            <div>
              În abordarea sa, Andreea îi îndrumă pe cursanți să observe detalii, să exploreze proporții, forme și texturi, ajutându-i să-și dezvolte gândirea critică și să descopere noi perspective asupra spațiului. Lecțiile sunt adaptate fiecărui nivel de înțelegere și interes, într-un mediu sigur și deschis, unde experimentarea și greșelile sunt parte din procesul de învățare.
            </div>
            <div>
              Cu răbdare și dedicare, Andreea sprijină dezvoltarea creativității, a capacității de observație și a încrederii în sine, pregătindu-i pe cursanți pentru viitor. Abordarea sa îi ajută pe aceștia să perceapă arhitectura ca o formă de explorare creativă a lumii în care trăim.
            </div>
          </div>
        </div>
        <div className='team-info-section'>
          <div className='team-info-section-text-body'>
            <div>
              Pasiunea pentru arte plastice a început cu desen grafit, uleiuri pe pânză și a evoluat pe parcursul formării profesionale în direcții diverse.
            </div>
            <div>
              Ca voluntar, a reprezentat România în Wuppertal, Germania, la concursul Solar Decathlon Europe cu un concept de casa sustenabilă unde a participat la fazele de proiectare și șantier. Experiența tehnică de modelare 3D și vizualizare de arhitectură, îl ajută pe Alex sa fie o persoana care împarte din experiența personală și oferă o viziune de ansamblu cursanților.
            </div>
            <div>
              Alex crede într-o înțelegere multidisciplinară a arhitecturii și a designului grafic și este de părere că nu trebuie să ne limităm la un singur mediu de lucru. Pictura, fotografia, schițele, designul pe calculator, muzica sau matematica, sunt toate ramuri care pot fi valorificate în concepte valoroase. Tot bagajul de abilități își va avea folosul la timpul potrivit și nu trebuie să ne rezumăm la un drum bătătorit. Alex reușește să implementeze la modulele 1 și 2 o gândire critică și o dezvoltare pe plan personal care să ofere baze solide micilor artiști.
            </div>
          </div>
          <div className='team-info-section-image-with-caption'>
            <img src='./team_alex_duta.jpeg' alt='team-alex-duta'/>
            <div>Alex Duță</div>
          </div>
        </div>
      </div>
    );
  }
  
  export default AboutUsPage;
  